@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Poppins', sans-serif;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.react-pdf__Document {
  @apply w-full;
}

.form-upload-modal {
  .filepond--wrapper {
    width: 100%;
  }
  .filepond--wrapper,
  .filepond--root,
  .filepond--drop-label {
    height: 300px !important;
  }
}

.modal-dialog {
  .MuiPaper-root {
    border-radius: 20px;
  }
}

@keyframes ring {
  0% {
    transform: rotate(35deg);
  }
  12.5% {
    transform: rotate(-30deg);
  }
  25% {
    transform: rotate(25deg);
  }
  37.5% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(15deg);
  }
  62.5% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes animatedDash {
  to {
    stroke-dashoffset: 1000;
  }
}

.rectPlaceHolder {
  animation: animatedDash 15s linear infinite;
  animation-direction: reverse;
  stroke-dasharray: 10;
}

.onDraw * {
  pointer-events: none;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
}

.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #dddddd;
  border-radius: 100vh;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}

.field-placeholder {
  position: fixed;
  background-color: rgba($color: grey, $alpha: 0.2);
  border: 2px dashed grey;
}

.annotation-placeholder {
  position: fixed;
  background-color: rgba($color: orange, $alpha: 0.2);
  border: 2px dashed orange;
}

.strikethrough-line {
  position: fixed;
  height: 0;
  transform-origin: top left;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
    no-repeat 95% 50%;
  background-color: #fff;
}
.spinner {
  width: 50px;
  height: 50px;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}
@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(360deg);
  }
}
@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(180deg);
    -webkit-transform: perspective(120px) rotateX(180deg);
  }
  100% {
    transform: perspective(120px) rotateX(360deg);
    -webkit-transform: perspective(120px) rotateX(360deg);
  }
}

.animate-linear-progress {
  animation: progress 2s linear infinite;
}

@keyframes progress {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

.editable-fields {
  .toolbox {
    @apply absolute;
    font-size: small;
    display: none;
  }
  &:hover .toolbox {
    display: block;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.saved-draft {
  animation: fadeOut 400ms linear 5s forwards;
}

.saving-draft {
  animation: fadeIn 300ms linear;
}

@media print {
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: unset !important;
  }
  .react-pdf__Page__annotations {
    display: none !important;
  }
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  animation: dot-elastic 1s infinite linear;
  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: inherit;
    animation: dot-elastic-before 1s infinite linear;
  }
  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: inherit;
    animation: dot-elastic-after 1s infinite linear;
  }
}
